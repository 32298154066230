'use client'
import React from 'react';
import {MdArrowUpward, MdLight, MdLogin, MdOutlineAssessment} from "react-icons/md";
import {useRouter} from 'next/navigation';

const LandingPage = () => {
    const router = useRouter();

    const handleLoginRedirect = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        router.push('/login');
    };

    return (
        <div className="relative min-h-screen overflow-hidden bg-custom-gradient">
            <div className="absolute inset-0">
                <div className="absolute inset-0 bg-gradient-to-b from-transparent via-white/20 to-white/70"/>
            </div>

            <div className="relative z-10 p-6">
                <div className="max-w-6xl mx-auto">
                    <nav
                        className="flex justify-between items-center mb-12 p-3 rounded-lg bg-white/30 backdrop-blur-sm border border-white/30 shadow-sm">
                        <div className="text-xl font-bold text-PrBlue">EON</div>
                        <div className="flex items-center gap-6">
                            <a href={"/about"} className="text-sm text-gray-800 hover:text-gray-900 transition-colors">About</a>
                            <button
                                onClick={handleLoginRedirect}
                                className="bg-white/40 backdrop-blur-sm hover:bg-white/50 text-gray-800 px-3 py-1.5 rounded-full flex items-center gap-1.5 transition-colors border border-white/30 shadow-sm text-sm">
                                Sign up <MdLogin size={14}/>
                            </button>
                        </div>
                    </nav>

                    <div className="text-center mb-32">
                        <div
                            className="inline-block bg-white/40 backdrop-blur-xl text-gray-800 px-4 py-2 rounded-full mb-8 border border-white/40 shadow-sm">
                            ✨ Internal Beta Access ✨
                        </div>

                        <h1 className="mb-8">
                            <div className="font-nokaBold text-PrBlue text-9xl font-black mb-4">EON</div>
                            <span className="text-5xl text-gray-900  block">
                                Your AI Assistant for
                                <span
                                    className="font-nokaBold text-PrBlue"> PayRetailers</span>
                            </span>
                        </h1>

                        <p className="text-gray-700 text-2xl mb-16 max-w-3xl mx-auto leading-relaxed">
                            EON is your intelligent companion for accessing PayRetailers' fintech knowledge, payment
                            solutions, and operational insights through natural conversations.
                        </p>

                        <div className="flex justify-center gap-4">
                            <button
                                onClick={handleLoginRedirect}
                                className="bg-PrBlue text-white px-8 py-4 rounded-full flex items-center gap-3 transition-all text-lg font-semibold shadow-lg hover:shadow-xl hover:bg-blue-800">
                                Get Started <MdArrowUpward size={20}/>
                            </button>
                        </div>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-24 py-10">
                        <div
                            className="p-8 rounded-2xl bg-white/40 backdrop-blur-xl border border-white/40 hover:bg-white/50 transition-all group shadow-sm">
                            <div className="bg-purple-100 w-12 h-12 rounded-full flex items-center justify-center mb-6">
                                <MdLight className="text-purple-600" size={24}/>
                            </div>
                            <h3 className="text-xl font-semibold text-gray-900 mb-4">Fintech Knowledge Hub</h3>
                            <p className="text-gray-700">Access instant information about PayRetailers' payment
                                solutions, compliance guidelines, and financial services through conversational AI.</p>
                        </div>

                        <div
                            className="p-8 rounded-2xl bg-white/40 backdrop-blur-xl border border-white/40 hover:bg-white/50 transition-all group shadow-sm">
                            <div className="bg-blue-100 w-12 h-12 rounded-full flex items-center justify-center mb-6">
                                <MdOutlineAssessment className="text-blue-600" size={24}/>
                            </div>
                            <h3 className="text-xl font-semibold text-gray-900 mb-4">Operations Support</h3>
                            <p className="text-gray-700">Get real-time assistance with transaction processing, merchant
                                onboarding, and payment gateway operations to streamline your workflow.</p>
                        </div>

                        <div
                            className="p-8 rounded-2xl bg-white/40 backdrop-blur-xl border border-white/40 hover:bg-white/50 transition-all group shadow-sm">
                            <div className="bg-green-100 w-12 h-12 rounded-full flex items-center justify-center mb-6">
                                <MdOutlineAssessment className="text-green-600" size={24}/>
                            </div>
                            <h3 className="text-xl font-semibold text-gray-900 mb-4">Partner Ecosystem</h3>
                            <p className="text-gray-700">Easily navigate PayRetailers' network of financial partners,
                                payment methods, and integration solutions across Latin America.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LandingPage;